import React, { useState, useMemo, useCallback } from 'react';
import './MelodyApp.css';
import Slider from './Slider';
import Selector from './Selector';
import GenerateSequences from './GenerateSequences';
import MelodyCanvas from './MelodyCanvas';
import ReactSlider from 'react-slider';
import Instruments from './Roles';
import characters from './characters.json'

function MelodyApp(colorModeClass) {
  //  Enumerated types:
  // eslint-disable-next-line
  const noteSizes = [ '1/2', '1/4', '1/8', '1/16', '1/32' ];
  const keys = useMemo(() => [ 'C', 'C#/Db', 'D', 'D#/Eb', 'E', 'F', 'F#/Gb', 'G', 'G#/Ab', 'A', 'A#/Bb', 'B' ], []);
  const scales = [ 'Ionian', 'Dorian', 'Phrygian', 'Lydian', 'Mixolydian', 'Aeolian', 'Locrian', 'Minor Pentatonic', 'Major Pentatonic', 'Minor Blues', 'Major Blues', 'Diminished', 'Whole Tone', 'Chromatic' ]
  //  User Selected Parameters:
  const [ userSequences, setUserSequences ] = useState(1);
  const [ userMeasures, setUserMeasures ] = useState(2);
  const [ userBeatsPerMeasure, setUserBeatsPerMeasure ] = useState(4);
  const [ userBeatSize, setUserBeatSize ] = useState(noteSizes[1]);
  const [ userNoteSize, setUserNoteSize ] = useState(noteSizes[3]);
  const [ userRestsToStart, setUserRestsToStart ] = useState(0);
  const [ userNotesInPhrase, setUserNotesInPhrase ] = useState(24);
  const [ userKey, setUserKey ] = useState(keys[0]);
  const [ userScale, setUserScale ] = useState(scales[0]);
  const [ userLowestMIDINote, setUserLowestMIDINote ] = useState(21);
  const [ userHighestMIDINote, setUserHighestMIDINote ] = useState(108);
  const [ userVariance, setUserUserVariance ] = useState(3);
  const [ userBusyness, setUserBusyness ] = useState(50);
  const [ userLegato, setUserLegato ] = useState(30);
  //  Array for the resulting sequences:
  const [ sequences, setSequences ] = useState([]);

  //  Some calculated values based on user inputs:
  const maxEvents = useMemo(() => userMeasures * userBeatsPerMeasure * 2 ** (noteSizes.findIndex((item ) => item === userNoteSize) / noteSizes.findIndex((item ) => item === userBeatSize) - 1), [userMeasures, userBeatsPerMeasure, userNoteSize, userBeatSize, noteSizes])
  const ticksPerEvent = useMemo(() => 128 / 2 ** ((noteSizes.findIndex((item ) => item === userNoteSize)) - (noteSizes.findIndex((item ) => item === userBeatSize))), [userNoteSize, userBeatSize, noteSizes]);
  const lowestNote = useMemo(() => keys[userLowestMIDINote % 12] + Math.floor(userLowestMIDINote / 12), [userLowestMIDINote, keys]);
  const highestNote = useMemo(() => keys[userHighestMIDINote % 12] + Math.floor(userHighestMIDINote / 12), [userHighestMIDINote, keys]);

  const handleSubmit = () => {
    setSequences( previousSequences => [
      ...previousSequences, 
      GenerateSequences( 
        keys,
        scales,
        userSequences,
        userRestsToStart,
        userNotesInPhrase,
        userKey,
        userScale,
        userLowestMIDINote,
        userHighestMIDINote,
        userVariance,
        userBusyness,
        userLegato,
        maxEvents
      )
    ] );
  };

  const DynamicSlider = useCallback(() => {
    return (
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        defaultValue={[userRestsToStart, userNotesInPhrase]}
        ariaValuetext={state => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        min={0}
        max={maxEvents}
        minDistance={1}
        withTracks={true}
        onChange={(selection) => {
          setUserRestsToStart(selection[0]);
          setUserNotesInPhrase(selection[1])
        }}
      />)
  }, [maxEvents, userRestsToStart, userNotesInPhrase])

  const MidiRange = useCallback(() => {

    return (
      <div className="doubleSlider">              
        <p>Choose the MIDI range for the notes in the sequence:</p><p>  {lowestNote} - {highestNote}</p>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          defaultValue={[userLowestMIDINote, userHighestMIDINote]}
          ariaLabel={['Lower MIDI Note', 'Upper MIDI Note']}
          ariaValuetext={state => `Thumb value ${state.valueNow}`}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          pearling
          min={0}
          max={127}
          marks={[0, 33, 66, 99, 132]}
          minDistance={12}
          withTracks={true}
          onChange={(selection) => {
            setUserLowestMIDINote(selection[0]);
            setUserHighestMIDINote(selection[1])
          }}
        />
      </div>
      )

  }, [userLowestMIDINote, userHighestMIDINote, lowestNote, highestNote]);

  const characterUpdates = (character) => {
    setUserUserVariance(characters[character]["variance"]);
    setUserBusyness(characters[character]["busyness"]);
    setUserLegato(characters[character]["legato"]);
    setUserNoteSize(characters[character]["noteSize"]);
    setUserScale(characters[character]["scale"]);
  };
  
  return (
    <div className={colorModeClass}>
      <div>
        <div className="panel">
          <h1>Random MIDI Melody Generator</h1>
        </div>
        <div className="panel">
          <Instruments {...{ setUserLowestMIDINote, setUserHighestMIDINote }}/>
        </div>
        <div className="panel">
          <div className="sliders">
            <Slider label="Number of Sequences:  " userValue={userSequences} setUserValue={setUserSequences} min={1} max={20} />
            <Slider label="Number of Measures:  " userValue={userMeasures} setUserValue={setUserMeasures} min={1} max={32} />
            <Slider label="Number of Beats Per Measures:  " userValue={userBeatsPerMeasure} setUserValue={setUserBeatsPerMeasure} min={2} max={17} />
            <div className="doubleSlider">              
              <p>Choose when the sequence starts and ends within the measures:</p>
              <DynamicSlider />
            </div>
            <MidiRange />
            <Slider label="Largest Note Jump In The Scale:  " userValue={userVariance} setUserValue={setUserUserVariance} min={1} max={12} />
            <Slider label="Probability of an Event Being A Note:  " userValue={userBusyness} setUserValue={setUserBusyness} min={1} max={100} />
            <Slider label="Probability of Held Notes:  " userValue={userLegato} setUserValue={setUserLegato} min={0} max={100} />
          </div>
          <div className="pick_list">
            <div className="pick_item">
              <p>
                <span>Note that counts as a beat:  </span>
                <Selector 
                    items={noteSizes} 
                    currentSelection={noteSizes.findIndex((item) => item === userBeatSize)} 
                    onSelect={(selectedItem) => setUserBeatSize(selectedItem)}
                />
              </p>
            </div>
            <div className="pick_item">
              <p>
                  <span>Note size:   </span> 
                  <Selector 
                      items={noteSizes} 
                      currentSelection={noteSizes.findIndex((item) => item === userNoteSize)}
                      onSelect={(selectedItem) => setUserNoteSize(selectedItem)}
                  />
              </p>
            </div>
            <div className="pick_item">
              <p>
                <span>Key:   </span> 
                <Selector 
                    items={keys} 
                    currentSelection={keys.findIndex((item) => item === userKey)}
                    onSelect={(selectedItem) => setUserKey(selectedItem)}
                />
              </p>
            </div>
            <div className="pick_item">
              <p>
                  <span>Mode or scale:   </span> 
                  <Selector
                      items={scales} 
                      currentSelection={scales.findIndex((item) => item === userScale)}
                      onSelect={(selectedItem) => setUserScale(selectedItem)}
                  />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button id='submit' onClick={handleSubmit}>Submit</button>
      </div>
      <div className="characters">
        {Object.keys(characters).map((name) => <img key={name} src={`./melodies/characters/${name.toLowerCase()}.png`} alt={name} title={name} onClick={() => characterUpdates(name)}/>)}
      </div>
      {sequences.map((items, instance) => (
        <div className='melodyCharts' key={'SequenceGroup-' + instance}>
          {items.map((sequence, index) => <MelodyCanvas {...{ instance, sequence, index, ticksPerEvent}} />)}
        </div>
      ))}
    </div>
  );
}

export default MelodyApp;
