// import { useMemo } from "react";
import GenerateScale from "./musicalFunctions";

const GenerateSequences = ((
        keys,
        scales,
        userSequences,
        userRestsToStart,
        userNotesInPhrase,
        userKey,
        userScale,
        userLowestMIDINote,
        userHighestMIDINote,
        userVariance,
        userBusyness,
        userLegato,
        maxEvents
    ) => {
    const tonicIntervals = GenerateScale( scales, userScale );

    //
    //          Define the scale in the first (lowest octave).  Values are MIDI note numbers.
    //
    // Find the position of the tonic in the list of notes array to establish the first octave.
    let firstOctave = [];
    let notesInScale = [];
    firstOctave.push(keys.findIndex((item) => item === userKey));
    
    //
    //  Create an array of first octave notes in the scale.
    //
    tonicIntervals.forEach((item, index) => {
        let nextNote = firstOctave[index] + item;
        if (nextNote < 12) {
            firstOctave.push(nextNote)
        } else {
            firstOctave.push(nextNote - 12)
        };
    });
    firstOctave.sort(function(a, b){return a - b});
    //
    //  Create the complete list of notes in the scale.
    //
    for (let i=0; i < 128; i++) {
        if ((i >= userLowestMIDINote) && (i <= userHighestMIDINote)) {
            if (firstOctave.includes(i % 12)) {
                notesInScale.push(i)
            }
        };
    };

    //
    //       Create a cadence that will be used in the generation of all the sequences.
    //
    let sequenceCadence = cadenceMaker(
        userRestsToStart, 
        userNotesInPhrase, 
        maxEvents, 
        userBusyness, 
        userLegato
    );

    function cadenceMaker(restsStart, events, totalEvents, probOfNote, probOfHold) {
        //  function to create a rhythmic cadence for the melodies  
        let cadence = [];
        const restsEnd = totalEvents - events;
        for (let i = 0; i < restsStart; i++) {
            cadence.push('R');
        };
        let noteInPlay = false;
        for (let i = 0; i < events - restsStart; i++){
            if (Math.random()*100 < probOfNote) {
                if (noteInPlay) {
                    if (Math.random()*100 < probOfHold) {
                        cadence.push('H');
                    } else {
                        cadence.push('N');
                    };                                    
                } else {
                    cadence.push('N');
                    noteInPlay = true
                };
            } else {
                cadence.push('R');
                noteInPlay = false
            };
        };
        for (let i = 0; i < restsEnd; i++) {
            cadence.push('R')
        }; 
        return cadence;              
    };    
    
    //
    //        Generate melodies based on the cadence and the notes from the scale.
    //
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is exclusive and the minimum is inclusive
    }; 
    
    //
    //        Normal distribution is used to determine intervals.
    //
    function getRandomNormal(mean, variance) {
        const stdDev = Math.sqrt(variance);

        // Generate two random uniform numbers between 0 and 1
        const u1 = Math.random();
        const u2 = Math.random();
    
        // Apply Box-Muller transform to get standard normal random numbers
        const z0 = Math.sqrt(-2 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
    
        // Scale and shift to mean and stdDev
        return Math.round(z0 * stdDev + mean)
    };
                        
    function melodyMaker(modeIntervals, cadence, variance) {
    //        function to convert scale steps into MIDI note numbers acording to the cadence
        let currentNote = null;
        const firstNote = getRandomInt(0, modeIntervals.length - 1);
        let melody = [];
        for (let x = 0; x < cadence.length; x++) {
            if (cadence[x] === 'N') {
                if (currentNote === null) {
                    melody.push(modeIntervals[firstNote]);
                    currentNote = firstNote;
                } else {
                    currentNote = currentNote + Math.floor(getRandomNormal(0, variance ));
                    if (currentNote >= 0 && currentNote < modeIntervals.length) {
                        melody.push(modeIntervals[currentNote]);
                    } else if (currentNote < 0) {
                        currentNote = Math.abs(getRandomInt(0, variance));
                        if (currentNote > modeIntervals.length - 1) {currentNote = modeIntervals.length - 1}
                        melody.push(modeIntervals[currentNote]);
                    } else {
                        currentNote = modeIntervals.length - 1 - Math.abs(getRandomInt(0, variance));
                        if (currentNote < 0) {currentNote = 0}
                        melody.push(modeIntervals[currentNote]);
                    }
                }
            } else {
                melody.push(cadence[x]);
            };
        };
        return melody;
    };

    //
    //   Generate all the sequences requested.
    //
    let sequences = [];
    for (let i = 0; i < userSequences; i++) {
        let sequence = melodyMaker(notesInScale, sequenceCadence, userVariance);
        sequences.push(sequence);
    }

    return sequences;
});

export default GenerateSequences;