import React, { useEffect, useState } from 'react';

const Selector = ({ items, currentSelection, onSelect }) => {
  const [selectedItem, setSelectedItem] = useState(items[currentSelection]);
  useEffect(() => {setSelectedItem(items[currentSelection])}, [items, currentSelection]);

  const handleSelect = (item) => {
    setSelectedItem(item);
    onSelect(item); // Call the callback function with the selected item
  };

  return (
    <select
      id={selectedItem}
      value={selectedItem}
      onChange={(e) => handleSelect(e.target.value)}
    >
      {items.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

export default Selector;